import { Menu, MenuButton, MenuItem } from '@szhsin/react-menu';
import React from 'react';
import DealsHeading from '../components/CommonSections/DealsHeading';
import SEO from '../components/SEO';
import { MainTitle, SecondaryDescription, SecondaryTitle } from '../components/Typography';
import TriangularArrowDown from '../assets/images/icons/other/triangular-arrow-down.inline.svg';
import { useCallback } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import DealHighlights from '../components/Deals/DealHighlights';
import useWindow from '../hooks/useWindow';
import ModalsFlow from '../components/Modals/ModalsFlow';
import ContactWidget from '../components/ContactWidget';

const navLinks = [
  {
    title: 'Home',
    leadsTo: '/',
  },
  {
    title: 'Remortgage',
    leadsTo: '/mortgages/remortgage',
  },
  {
    title: 'View Deals',
    leadsTo: '/view-deals',
  },
  {
    title: 'Deal Highlights',
    leadsTo: null,
    isActive: true,
  },
];

function DealHighlightsPage({location}) {
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [dealData, setDealData] = useState(null);
  const [dropdownValue, setDropdownValue] = useState({
    title: null,
    sub_title: null,
  });
  const [selectedDeal, setSelectedDeal] = useState({
    rowIndex: null,
    dealIndex: null,
  });
  const [selectedRow, setSelectedRow] = useState(null);
  const { isMobile } = useWindow();

  const handleClick = useCallback(
    (index) => {
      setDropdownValue(dealData.tabData.product_category[index]);
      setSelectedRow(index);
    },
    [dealData]
  );

  const handleDealSelection = useCallback(
    (dealIndex, customRow) => {
      let currentRowIndex = selectedRow;

      if (customRow || customRow === 0) {
        currentRowIndex = customRow;
      }

      setSelectedDeal({
        rowIndex: currentRowIndex,
        dealIndex,
      });
    },
    [selectedRow]
  );

  const generateDropdownItems = useCallback(() => {
    if (!dealData) return;

    const components = [];

    dealData.tabData.product_category.forEach((item, index) => {
      if (index === dealData.rowIndex && !dropdownValue) return;
      else if (item.title === dropdownValue.title) return;

      components.push(
        <MenuItem key={index} onClick={() => handleClick(index)}>
          <div className="highlights__dropdown-text">
            <SecondaryTitle>{item?.title}</SecondaryTitle>
            <SecondaryDescription>{item?.sub_title}</SecondaryDescription>
          </div>
        </MenuItem>
      );
    });

    return components;
  }, [dealData, dropdownValue, selectedRow]);

  useEffect(() => {
    if (!dealData) return;

    setDropdownValue(dealData.tabData.product_category[dealData.rowIndex]);
    setSelectedRow(dealData.rowIndex);
    handleDealSelection(dealData.dealIndex, dealData.rowIndex);
  }, [dealData]);

  useEffect(() => {
    setDealData(JSON.parse(localStorage.getItem('dealData')));
  }, []);

  return (
    <>
      <SEO title="Deal highlights" />
      <div className="common-page">
        <div className="highlights">
          <DealsHeading
            links={location?.state?.customLinks ? location?.state?.customLinks.slice(0, 3).concat(navLinks.slice(2)) : navLinks}
            leftContent={
              <div className="highlights-header">
                <MainTitle>Deal Highlights</MainTitle>
                <Menu
                  transition
                  direction="bottom"
                  viewScroll="initial"
                  position="initial"
                  menuButton={
                    <MenuButton>
                      <div className="highlights__dropdown-text">
                        <SecondaryTitle>{dropdownValue.title}</SecondaryTitle>
                        <SecondaryDescription>{dropdownValue.sub_title}</SecondaryDescription>
                      </div>
                      <TriangularArrowDown />
                    </MenuButton>
                  }
                >
                  <>{generateDropdownItems()}</>
                </Menu>
              </div>
            }
          />
          {!isMobile && <span className="highlights__separator" />}
          <DealHighlights
            allDeals={dealData?.tabData?.product_category}
            productId={dealData?.productId}
            dealHighlightsData={dropdownValue.products}
            selectedDeal={selectedDeal}
            selectedRow={selectedRow}
            setSelectedDeal={(dealIndex) => handleDealSelection(dealIndex)}
          />
          <ContactWidget
            handleClick={() => setIsContactModalOpen(true)}
            title="Speak to a mortgage expert for FREE"
          />
          <ModalsFlow
            isFirstOpened={isContactModalOpen}
            setIsFirstOpened={(state) => setIsContactModalOpen(state)}
            firstModalIndex={1}
            usePureCalendlyWidget
          />
        </div>
      </div>
    </>
  );
}

export default DealHighlightsPage;
